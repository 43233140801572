export const actionTypes = {
    GET_DOMAIN_LIST: "GET_DOMAIN_LIST",
    GET_DOMAIN_LIST_SUCCESS: "GET_DOMAIN_LIST_SUCCESS",
    GET_DOMAIN_LIST_ERROR: "GET_DOMAIN_LIST_ERROR",
    SELECTED_DOMAIN: "SELECTED_DOMAIN",
    SET_PAGE: "SET_PAGE",
    SET_PAGE_SIZE: "SET_PAGE_SIZE",
}

export const actions = {
    getDomainList: (pagination) => ({ type: actionTypes.GET_DOMAIN_LIST, payload: pagination }),
    getDomainListSuccess: (data) => ({ type: actionTypes.GET_DOMAIN_LIST_SUCCESS, payload: data }),
    getDomainListError: (errors) => ({ type: actionTypes.GET_DOMAIN_LIST_ERROR, payload: { errors } }),
    selectedDomain: (domainInfo) => ({type: actionTypes.SELECTED_DOMAIN, payload: domainInfo}),
    setPage: (num) => ({type: actionTypes.SET_PAGE, payload: num}),
    setPageSize: (num) => ({type: actionTypes.SET_PAGE_SIZE, payload: num}),
}
