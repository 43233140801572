import {actionTypes} from './domains.action';

const initState = {
    domainsList: [],
    isLoading: true,
    errors: "",
    totalRecords: 0,
    pageNumber: 1,
    pageSize: 5,
    selectedDomain: null,
    refreshDomains: true,
}

export const reducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.GET_DOMAIN_LIST: {
            return {
                ...state,
                isLoading: true,
                errors: "",
                selectedDomain: null,
            }
        }
        case actionTypes.GET_DOMAIN_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                domainsList: action.payload.list,
                totalRecords: action.payload.total,
                refreshDomains: false,
            }
        }
        case actionTypes.GET_DOMAIN_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
                errors: action.payload.errors
            }
        }
        case actionTypes.SET_PAGE: {
            return {
                ...state,
                pageNumber: action.payload,
                refreshDomains: true,
            }
        }
        case actionTypes.SET_PAGE_SIZE: {
            return {
                ...state,
                pageSize: action.payload,
                refreshDomains: true,
            }
        }
        case actionTypes.SELECTED_DOMAIN: {
            return {
                ...state,
                selectedDomain: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}