import React from 'react';
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls";
import { ArtWorkHistoryTable } from './ArtWorkHistoryTable';

export function ArtWorkHistoryCard() {
    return (
        <Card>
            <CardHeader title="ArtWork History List">
            </CardHeader>
            <CardBody>
                <ArtWorkHistoryTable />
            </CardBody>
        </Card>
    )
}

