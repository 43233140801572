import React, { useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { NoRecordsFoundMessage, PleaseWaitMessage } from "../../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { ClipartsFormatter } from './clipartsTableFormatter';
import { ArtWorkActions } from '../../_redux/artwork.action';
import * as constants from '../../constant';
import { ReactSVG } from 'react-svg'

export default function ClipartsTable(
    { onOpenDeleteClipartModal, onOpenClipartModal, setSelectedClipart,statusClipart }
    ) {

    const dispatch = useDispatch();

    const {
        isLoading, totalClipartRecords, clipartPageNumber, clipartPageSize, clipartsList, refreshCliparts,selectedCategory
    } = useSelector(state => state.artwork, shallowEqual);

    useEffect(() => {
        if (refreshCliparts) {
            dispatch(ArtWorkActions.getAllClipartsList({ batch: clipartPageNumber - 1, limit: clipartPageSize, selectedCategory }));
        }
    }, [refreshCliparts]);

    const columns = [
        {
            dataField: "_id",
            text: "ID",
            hidden: true,
        },
        {
            dataField: "categoryName",
            text: "Add Category",
        },
        {
            dataField: "imageUrl",
            text: "Image",
            formatter: (cell) => (
                cell.indexOf("svg") != -1 ?
                    <ReactSVG src={cell} className="shapes-svg" /> :

                    <img src={cell} height="80" />
            )
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ClipartsFormatter,
            formatExtraData: {
                onOpenDeleteClipartModal: onOpenDeleteClipartModal,
                onOpenClipartModal: onOpenClipartModal,
                setSelectedClipart: setSelectedClipart,
                statusClipart:statusClipart
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },]

    const paginationOptions = {
        custom: true,
        totalSize: totalClipartRecords,
        sizePerPageList: constants.sizePerPageList,
        sizePerPage: clipartPageSize,
        page: clipartPageNumber,
    };

    const noDataIndication = () => {
        return (
            <>
                {
                    isLoading ? (
                        <PleaseWaitMessage entities={null} />
                    ) : (
                            <NoRecordsFoundMessage entities={clipartsList} />
                        )
                }
            </>
        )
    }

    const onTableChange = (type, newState) => {
        if (type === "pagination") {
            if (newState.page && newState.page !== clipartPageNumber) {
                dispatch(ArtWorkActions.setClipartPage(newState.page));
            }
            if (newState.sizePerPage !== clipartPageSize) {
                dispatch(ArtWorkActions.setClipartPageSize(newState.sizePerPage));
            }
        }
    }

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={isLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="_id"
                                data={clipartsList}
                                columns={columns}
                                {...paginationTableProps}
                                noDataIndication={noDataIndication}
                                onTableChange={onTableChange}
                            >
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}