import React, { Suspense, lazy, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, Route } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { DomainManagementPage, DomainSummary } from './pages/DomainManagementPage';
// import { ProductsManagementPage } from './pages/ProductManagementPage';
import { ArtworkManagementPage } from './pages/ArtworkManagementPage';
import { ArtWorkHistoryManagementPage } from './pages/ArtWorkHistoryManagementPage';

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

const ProductsManagementPage = lazy(() =>
  import('./pages/ProductManagementPage')
)

const UserManagementPage = lazy(() =>
  import('./pages/UserManagementPage')
)

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const { enqueueSnackbar } = useSnackbar();

  const {messages} = useSelector(state => state.snackbar, shallowEqual);

  useEffect(() => {
    console.log(messages)
    if(messages.length) {
      let latestmsg = messages[messages.length - 1];
      enqueueSnackbar(latestmsg.message, {variant: latestmsg.variant})
    }
  }, [messages])

  

  return (
    
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          {/* DashboardPage */}
          <ContentRoute exact path="/dashboard" component={DashboardPage} />
          {/* Domain Management */}
          {/* <ContentRoute path="/domain-management" component={DomainManagementPage} />
          <ContentRoute path="/domain-summary" component={DomainSummary} /> */}
          {/* Product management */}
          <ContentRoute path="/product-management" component={ProductsManagementPage} />
          {/* Artwork management */}
          <ContentRoute path="/artwork-management" component={ArtworkManagementPage} />
          {/* Artwork History management */}
          <ContentRoute path="/artworkHistory-management" component={ArtWorkHistoryManagementPage} />
          <ContentRoute path="/user-management" component={UserManagementPage} />

          <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/my-page" component={MyPage} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
  );
}
