import { ArtWorkMap } from './artwork.action';

const initState = {
    modalAddCliparts: false,
    modalAddShapes: false,
    modalDeleteCliparts: false,
    modalDeleteShapes: false,
    isLoading: true,
    totalClipartRecords: 0,
    clipartPageNumber: 1,
    clipartPageSize: 10,
    clipartsList: [],
    refreshCliparts: true,
    shapesList: [],
    totalShapesRecords: 0,
    shapesPageNumber: 1,
    shapesPageSize: 10,
    refreshShapes: true,
    selectedCategory: '',
    categoryList: [],
}

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case ArtWorkMap.OPEN_CLIPARTS_MODAL: {
            return {
                ...state,
                modalAddCliparts: true
            }
        }
        case ArtWorkMap.CLOSE_CLIPARTS_MODAL: {
            return {
                ...state,
                modalAddCliparts: false
            }
        }
        case ArtWorkMap.OPEN_DELETE_CLIPART_MODAL: {
            return {
                ...state,
                modalDeleteCliparts: true
            }
        }
        case ArtWorkMap.CLOSE_DELETE_CLIPART_MODAL: {
            return {
                ...state,
                modalDeleteCliparts: false
            }
        }
        case ArtWorkMap.OPEN_ADD_SHAPES_MODAL: {
            return {
                ...state,
                modalAddShapes: true
            }
        }
        case ArtWorkMap.CLOSE_ADD_SHAPES_MODAL: {
            return {
                ...state,
                modalAddShapes: false
            }
        }
        case ArtWorkMap.OPEN_DELETE_SHAPES_MODAL: {
            return {
                ...state,
                modalDeleteShapes: true
            }
        }
        case ArtWorkMap.CLOSE_DELETE_SHAPES_MODAL: {
            return {
                ...state,
                modalDeleteShapes: false
            }
        }
        case ArtWorkMap.SET_CLIPART_PAGE: {
            return {
                ...state,
                clipartPageNumber: action.payload,
                refreshCliparts: true
            }
        }
        case ArtWorkMap.SET_CLIPART_PAGE_SIZE: {
            return {
                ...state,
                clipartPageSize: action.payload,
                refreshCliparts: true
            }
        }

        case ArtWorkMap.SET_SHAPES_PAGE: {
            return {
                ...state,
                shapesPageNumber: action.payload,
                refreshShapes: true
            }
        }
        case ArtWorkMap.SET_SHAPES_PAGE_SIZE: {
            return {
                ...state,
                shapesPageSize: action.payload,
                refreshShapes: true
            }
        }

        case ArtWorkMap.GET_ALL_CLIPARTS_LIST_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ArtWorkMap.GET_ALL_CLIPARTS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                clipartsList: action.payload.list,
                totalClipartRecords: action.payload.total,
                refreshCliparts: false,
                categoryList: action.payload.categoryList
            }
        }

        case ArtWorkMap.SELECTED_CATEGORY: {
            return {
                ...state,
                selectedCategory: action.payload,
                refreshCliparts: true,
            }
        }

        case ArtWorkMap.GET_ALL_CLIPARTS_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ArtWorkMap.ADD_CLIPARTS_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ArtWorkMap.ADD_CLIPARTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshCliparts: true
            }
        }
        case ArtWorkMap.ADD_CLIPARTS_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ArtWorkMap.DELETE_CLIPART_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ArtWorkMap.DELETE_CLIPART_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshCliparts: true
            }
        }
        case ArtWorkMap.DELETE_CLIPART_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ArtWorkMap.EDIT_CLIPART_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ArtWorkMap.EDIT_CLIPART_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshCliparts: true
            }
        }
        case ArtWorkMap.EDIT_CLIPART_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ArtWorkMap.UPDATE_CLIPART_STATUS_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ArtWorkMap.UPDATE_CLIPART_STATUS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshCliparts: true
            }
        }
        case ArtWorkMap.UPDATE_CLIPART_STATUS_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ArtWorkMap.GET_ALL_SHAPES_LIST_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ArtWorkMap.GET_ALL_SHAPES_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                shapesList: action.payload.list,
                totalShapesRecords: action.payload.total,
                refreshShapes: false,
            }
        }
        case ArtWorkMap.GET_ALL_SHAPES_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ArtWorkMap.ADD_SHAPES_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ArtWorkMap.ADD_SHAPES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshShapes: true
            }
        }
        case ArtWorkMap.ADD_SHAPES_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ArtWorkMap.DELETE_SHAPES_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ArtWorkMap.DELETE_SHAPES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshShapes: true
            }
        }
        case ArtWorkMap.DELETE_SHAPES_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ArtWorkMap.EDIT_SHAPES_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ArtWorkMap.EDIT_SHAPES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshShapes: true
            }
        }
        case ArtWorkMap.EDIT_SHAPES_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ArtWorkMap.UPDATE_SHAPES_STATUS_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ArtWorkMap.UPDATE_SHAPES_STATUS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refreshShapes: true
            }
        }
        case ArtWorkMap.UPDATE_SHAPES_STATUS_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}