import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import { actionTypes, actions } from './users.action';
import { allUsersListApi, activationStatusApi } from './users.api';

function* watchUserListSaga() {
    try {
        const { pageNumber, pageSize } = yield select(state => state.users);
        const data = yield call(allUsersListApi, { pageNumber, pageSize });;
        yield put(actions.getuserListSuccess(data));
    } catch (error) {
        yield put(actions.getuserListError(error.message));
    }
}

function* watchActivateUserStatusSaga({payload}) {
    try {
        const data = yield call(activationStatusApi, payload);;
        yield put(actions.activateUserStatusSuccess(data));
    } catch (error) {
        yield put(actions.activateUserStatusError(error.message));
    }
}

function* ActivateUserStatusSaga() {
    yield takeLatest(actionTypes.ACTIVATION_USER, watchActivateUserStatusSaga);
}

function* AllUserListSaga() {
    yield takeLatest(actionTypes.GET_USERS_LIST, watchUserListSaga);
}

export function* usersSaga() {
    yield all([
        fork(AllUserListSaga),
        fork(ActivateUserStatusSaga),
    ]);
}