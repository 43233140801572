import { ArtWorkHistoryMap } from './artworkHistory.action'

const initState = {
    isLoading: true,
    totalRecords: 0,
    pageNumber: 1,
    pageSize: 10,
    artWorkHistoryList: [],
    refreshArtWorkHistory: true,
}

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case ArtWorkHistoryMap.GET_ALL_ARTWORK_HISTORY_LIST_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ArtWorkHistoryMap.GET_ALL_ARTWORK_HISTORY_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                artWorkHistoryList: action.payload.list,
                totalRecords: action.payload.total,
                refreshArtWorkHistory: false,
            }
        }
        case ArtWorkHistoryMap.GET_ALL_ARTWORK_HISTORY_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ArtWorkHistoryMap.SET_ARTWORKHISTORY_PAGE: {
            return {
                ...state,
                pageNumber: action.payload,
                refreshArtWorkHistory: true,
            }
        }
        case ArtWorkHistoryMap.SET_ARTWORKHISTORY_PAGE_SIZE: {
            return {
                ...state,
                pageSize: action.payload,
                refreshArtWorkHistory: true,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}