// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,  
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";
import * as constants from '../constants';
import { actions } from '../_redux/domains.action';
import {ActionsColumnFormatter} from './tableFormatter';

export function DomainsTable() {

    const { 
        domainsList, isLoading, totalRecords, pageNumber, pageSize, refreshDomains 
    } = useSelector(state => state.domains, shallowEqual);

    const dispatch = useDispatch();
    const history = useHistory();

    // Table columns
    const columns = [
        {
            dataField: "_id",
            text: "ID",
            hidden: true,
        },
        {
            dataField: "clientName",
            text: "Client Name",
        },
        {
            dataField: "domain",
            text: "Domain",
        },
        {
            dataField: "isActivated",
            text: "Is Activated",
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionsColumnFormatter,
            //   formatExtraData: {
            //     openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
            //     openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
            //   },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    useEffect(() => {
        if(refreshDomains) {
            dispatch(actions.getDomainList({ batch: pageNumber - 1, limit: pageSize }));
        }
    }, [pageNumber, pageSize, refreshDomains]);

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalRecords,
        sizePerPageList: constants.sizePerPageList,
        sizePerPage: pageSize,
        page: pageNumber,
    };

    const noDataIndication = () => {
        return (
            <>
                {
                    isLoading ? (
                        <PleaseWaitMessage entities={null} />
                    ) : (
                            <NoRecordsFoundMessage entities={domainsList} />
                        )
                }
            </>
        )
    }
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            dispatch(actions.selectedDomain(row));
            history.push("/domain-summary");
        }
    }

    const onTableChange = (type, newState) => {
        if (type === "pagination") {
            if (newState.page && newState.page !== pageNumber) {
                dispatch(actions.setPage(newState.page));
            }
            if (newState.sizePerPage !== pageSize) {
                dispatch(actions.setPageSize(newState.sizePerPage));
            }
        }
    }

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={isLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="_id"
                                data={domainsList}
                                columns={columns}
                                {...paginationTableProps}
                                noDataIndication={noDataIndication}
                                onTableChange={onTableChange}
                                rowEvents={rowEvents}
                            >
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}
