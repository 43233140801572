import React from 'react';
import {  ArtWorkHistoryManagement } from '../modules/ArtWorkHistory'

const ArtWorkHistoryManagementPage = () => {
    return (
        <div>
            <ArtWorkHistoryManagement />
        </div>
    )
}

export { ArtWorkHistoryManagementPage };
