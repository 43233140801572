import React from 'react';
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const DashBoardCard = () => {

    return (
        <div>
            <div className="row">
                <div className="col-xl-6 col-md-6 mb-8" style={{ cursor: 'pointer' }}>
                    <Card>
                        <NavLink to="/product-management">
                            <CardBody>
                                Product Management
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
                                    />
                                </span>
                            </CardBody>
                        </NavLink>
                    </Card>
                </div>
                <div className="col-xl-6 col-md-6 mb-8" style={{ cursor: 'pointer' }}>
                    <Card>
                        <NavLink to="/artwork-management">
                            <CardBody>
                                Artwork Management
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
                                    />
                                </span>
                            </CardBody>
                        </NavLink>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-md-6 mb-8" style={{ cursor: 'pointer' }}>
                    <Card>
                        <NavLink to="/artworkHistory-management">
                            <CardBody>
                                Artwork History Management
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
                                    />
                                </span>
                            </CardBody>
                        </NavLink>
                    </Card>
                </div>
                <div className="col-xl-6 col-md-6 mb-8" style={{ cursor: 'pointer' }}>
                    <Card>
                        <NavLink to="/user-management">
                            <CardBody>
                                User Management
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
                                    />
                                </span>
                            </CardBody>
                        </NavLink>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default DashBoardCard;
