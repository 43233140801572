import React from 'react';
import { NavLink, Switch } from 'react-router-dom';
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls";
import { ContentRoute } from '../../../../_metronic/layout';
import Cliparts from './Cliparts';
import Shapes from './Shapes';

export const ArtworkCard = () => {
    return (
        <>
            <Card>
                <CardHeader title="Artwork list">
                </CardHeader>
                <CardBody>
                    <div className='d-flex nav-tabs'>
                        <NavLink exact className="nav-link" to='/artwork-management'>
                            <span>Cliparts</span>
                        </NavLink>
                        <NavLink className="nav-link" to='/artwork-management/artwork-shapes'>
                            <span>Shapes</span>
                        </NavLink>
                    </div>
                    <Switch>
                        <ContentRoute
                            path='/artwork-management'
                            exact
                            component={Cliparts}
                        />
                        <ContentRoute
                            path='/artwork-management/artwork-shapes'
                            component={Shapes}
                        />
                    </Switch>
                </CardBody>
            </Card>

        </>
    )
}

