import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import { actionTypes, actions } from './domains.action';
import { allDomainListApi } from './domains.api';

function* watchDomainListSaga({ payload }) {
    try {
        const tokens = yield select(state => state.auth.tokens);
        const data = yield call(allDomainListApi, {...payload, tokens});;
        yield put(actions.getDomainListSuccess(data));
    } catch (error) {
        yield put(actions.getDomainListError(error.message));
    }
}


function* AllDomainListSaga() {
    yield takeLatest(actionTypes.GET_DOMAIN_LIST, watchDomainListSaga);
}


export function* domainsSaga() {
    yield all([
        fork(AllDomainListSaga),
    ]);
}