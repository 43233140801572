import axios from 'axios';
// import {serverConfig} from '../../../utils/constants';

const getErrorMsg = (data) => {
    if(data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if(data.error) {
        return data.error.errors[0].responseMessage;
    }
    return "Login Error.";
}

export const loginApi = async (payload) => {
    let { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/admin/login`,
        data: payload,
        headers: {
            'Content-Type': "application/json",
        }
    });

    if(data.response && data.response.responseCode === 200) {
        return data.response.data;
    }

    throw new Error(getErrorMsg(data));
}

export const registerApi = async (payload) => {
    let { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/admin/signup`,
        data: payload,
        headers: {
            'Content-Type': "application/json",
        }
    });
    if(data.response && data.response.responseCode === 201) {
        return data.response.responseMessage;
    }

    throw new Error(getErrorMsg(data));
}

export const forgotPasswordApi = async (email) => {
    let { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/admin/forgotPassword`,
        data: {email},
        headers: {
            'Content-Type': "application/json",
        }
    });
    if(data.response && data.response.responseCode === 200) {
        return data.response;
    }

    throw new Error(getErrorMsg(data));
}

export const resetPasswordApi = async (payload) => {
    console.log(payload);
    let { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/admin/resetPassword`,
        data: payload,
        headers: {
            'Content-Type': "application/json",
        }
    });
    console.log(data);
    if(data.response && data.response.responseCode === 200) {
        return data.response;
    }

    throw new Error(getErrorMsg(data));
}