const initState = {
   messages: [],
}

export const SanckbarReducer = (state = initState, action) => {
    switch(action.type) {
        
        case "ADD_SNACKBAR": {
            return {
                messages: [...state.messages, action.payload]
            }
        }

        case "REMOVE_SNACKBAR": {
            if(state.messages.length) {
                state.messages.shift();
            }
            return {
                messages: state.messages.length ? state.messages : [],
            }
        }
      
        default: {
            return {
                ...state,
            }
        }
    }
}