import React from 'react';
import SVG from "react-inlinesvg";
import { Divider, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const DomainSummary = () => {
    const domainSummary = useSelector(state => state.domains.selectedDomain, shallowEqual);
    const history = useHistory();
    return (
        <>
            {
                !domainSummary && <Redirect to="/domain-management" />
            }
            {
                domainSummary &&
                <Card>
                    <CardHeader title="Domain Summary"></CardHeader>
                    <CardBody>
                        <div>

                            <ListItem>
                                <ListItemAvatar>
                                    {/* <Avatar> */}
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Layout/Layout-4-blocks.svg"
                                            )}
                                        />
                                    </span>
                                    {/* </Avatar> */}
                                </ListItemAvatar>
                                <div className="d-flex w-100">
                                    <ListItemText primary="Domain Name" />
                                    <ListItemText className="text-center" primary={`${domainSummary.domain}`} />
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <ListItemAvatar>
                                    {/* <Avatar> */}
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Communication/Group.svg"
                                            )}
                                        />
                                    </span>
                                    {/* </Avatar> */}
                                </ListItemAvatar>
                                <div className="d-flex w-100">
                                    <ListItemText primary="Client Name" />
                                    <ListItemText className="text-center" primary={`${domainSummary.clientName}`} />
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <ListItemAvatar>
                                    {/* <Avatar> */}
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Communication/Group.svg"
                                            )}
                                        />
                                    </span>
                                    {/* </Avatar> */}
                                </ListItemAvatar>
                                <div className="d-flex w-100">
                                    <ListItemText primary="Is Activated" />
                                    <ListItemText className="text-center" primary={`${domainSummary.isActivated}`} />
                                </div>
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <ListItemAvatar>
                                    {/* <Avatar> */}
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Communication/Group.svg"
                                            )}
                                        />
                                    </span>
                                    {/* </Avatar> */}
                                </ListItemAvatar>
                                <div className="d-flex w-100 ">
                                    <ListItemText primary="Status" />
                                    <ListItemText className="text-center" primary={`${domainSummary.status}`} />
                                </div>
                            </ListItem>

                        </div>
                    </CardBody>
                    <CardFooter >
                        <div className="d-flex justify-content-between">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => history.push('/domain-management')}
                            >
                                Back
                            </button>
                            <div>
                                {
                                    domainSummary.isActivated ? (
                                        <button
                                            type="button"
                                            className="btn btn-warn"
                                        >
                                            Deactivate
                                        </button>
                                    ) : (
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                            >
                                                Activate
                                            </button>
                                        )
                                }
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            }
        </>
    )
}
