import React, { useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { NoRecordsFoundMessage, PleaseWaitMessage } from "../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../_metronic/_partials/controls";
import * as constants from '../constants';
import { ArtWorkHistoryActions } from '../_redux/artworkHistory.action';
import { ActionFormatter } from './ArtworkActionFormatter';

export function ArtWorkHistoryTable() {

    const dispatch = useDispatch();

    const {
        artWorkHistoryList, isLoading, totalRecords, pageNumber, pageSize, refreshArtWorkHistory
    } = useSelector(state => state.artworkHistory, shallowEqual);


    useEffect(() => {
        if (refreshArtWorkHistory) {
            dispatch(ArtWorkHistoryActions.getAllArtworkHistoryList());
        }
    }, [refreshArtWorkHistory]);

    const onArtworkDownload = (row) => {
        let ele = document.createElement('a');
        ele.download = "artwork" + Date.now() + ".png";
        ele.href = row.imageUrl[0];
        ele.click();

    }

    const columns = [
        {
            dataField: "_id",
            text: "ID",
            hidden: true,
        },
        {
            dataField: "userDetails.firstName",
            text: "First Name",
        },
        {
            dataField: "userDetails.lastName",
            text: "Last Name",
        },
        {
            dataField: "userDetails.email",
            text: "Email",
        },
        {
            dataField: "imageUrl",
            text: "Artwork",
            formatter: (cell) => (
                <img crossOrigin="anonymous" src={cell[0]} height="80" />
            )
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: ActionFormatter,
            formatExtraData: {
                onArtworkDownload
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    const paginationOptions = {
        custom: true,
        totalSize: totalRecords,
        sizePerPageList: constants.sizePerPageList,
        sizePerPage: pageSize,
        page: pageNumber,
    };

    const noDataIndication = () => {
        return (
            <>
                {
                    isLoading ? (
                        <PleaseWaitMessage entities={null} />
                    ) : (
                            <NoRecordsFoundMessage entities={artWorkHistoryList} />
                        )
                }
            </>
        )
    }

    const onTableChange = (type, newState) => {
        if (type === "pagination") {
            if (newState.page && newState.page !== pageNumber) {
                dispatch(ArtWorkHistoryActions.setPage(newState.page));
            }
            if (newState.sizePerPage !== pageSize) {
                dispatch(ArtWorkHistoryActions.setPageSize(newState.sizePerPage));
            }
        }
    }

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={isLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="_id"
                                data={artWorkHistoryList}
                                columns={columns}
                                {...paginationTableProps}
                                noDataIndication={noDataIndication}
                                onTableChange={onTableChange}
                            >
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    )
}


