import React from 'react';
import { Formik, Form } from 'formik';
import { Modal, Button } from 'react-bootstrap';

const AddShapes = ({ onCloseShapesModal, selectedShape, onAddShape, onUpdateShapes }) => {

    return (
        <>
            <Formik
                initialValues={{
                    images: selectedShape ? selectedShape.images : ""
                }}

                onSubmit={(values) => {
                    if (selectedShape) {
                        onUpdateShapes(values, selectedShape)
                    } else {
                        onAddShape(values)
                    }
                    onCloseShapesModal();
                }}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <>
                        <Modal.Body className="overlay overlay-block">
                            <Form className="form form-label-right">
                                <div className="upload_document_file">
                                    {/* <div htmlFor="inputGroupFile03">
                                        <UploadPlusIcon />
                                    </div> */}
                                    {/* <label htmlFor="inputGroupFile03" className="upload_document" >UPLOAD IMAGE</label> */}
                                    <input
                                        type="file"
                                        multiple
                                        // className="custom-file-input"
                                        // id="inputGroupFile03"
                                        accept="image/*"
                                        onChange={event => setFieldValue('images', event.target.files)}
                                    />
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onCloseShapesModal}
                                className="float-right"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                onClick={() => handleSubmit()}
                                className="float-right"
                            >
                                {selectedShape ? "EDIT" : "ADD"}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AddShapes;
