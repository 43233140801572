import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import ShapesTable from './shapes/shapesTable';
import AddShapes from './shapes/addShapes';
import { DeleteShapeModal } from './shapes/deleteShapesModal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ArtWorkActions } from '../_redux/artwork.action';

const Shapes = () => {

    const [selectedShape, setSelectedShape] = useState(null);

    const dispatch = useDispatch();

    const onOpenShapesModal = () => {
        dispatch(ArtWorkActions.openAddShapes());
    }

    const onCloseShapesModal = () => {
        dispatch(ArtWorkActions.closeAddShapes());
        setSelectedShape(null)
    }

    const onOpenDeleteShapesModal = (row) => {
        setSelectedShape(row)
        dispatch(ArtWorkActions.openDeleteShapeModal());
    }

    const onCloseDeleteShapesModal = () => {
        dispatch(ArtWorkActions.closeDeleteShapeModal());
        setSelectedShape(null)
    }

    const onDeleteShapes = (_id) => {
        if (selectedShape) {
            dispatch(ArtWorkActions.deleteShapes(selectedShape._id))
            onCloseDeleteShapesModal()
        }
    }

    const onAddShape = (values) => {
        let data = new FormData();
        values.images.forEach(img => {
            data.append('images', img)
        })
        dispatch(ArtWorkActions.addShapes(data));
    }

    const onUpdateShapes = (data) => {
    }

    const statusShape = (data, isActive) => {
        dispatch(ArtWorkActions.updateShapesStatus({ _id: data._id, isActive }));
    }

    const { modalAddShapes, isLoading, modalDeleteShapes } = useSelector(state => state.artwork, shallowEqual)

    return (
        <div>
            <button
                type="button"
                className="btn btn-primary float-right mt-5"
                onClick={onOpenShapesModal}
            >
                Add
        </button>
            <Modal size="lg" show={modalAddShapes}>
                <Modal.Header >
                    <Modal.Title>
                        <h5 className='float-right'>Add Shapes</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddShapes
                        onCloseShapesModal={onCloseShapesModal}
                        selectedShape={selectedShape}
                        onAddShape={onAddShape}
                        onUpdateShapes={onUpdateShapes}
                    />
                </Modal.Body>
            </Modal>
            <ShapesTable
                onOpenDeleteShapesModal={onOpenDeleteShapesModal}
                onOpenShapesModal={onOpenShapesModal}
                selectedShape={selectedShape}
                setSelectedShape={setSelectedShape}
                statusShape={statusShape}
            />
            <DeleteShapeModal
                modalDeleteShapes={modalDeleteShapes}
                isLoading={isLoading}
                onCloseDeleteShapesModal={onCloseDeleteShapesModal}
                onDeleteShapes={onDeleteShapes}
            />
        </div>
    )
}

export default Shapes;
