import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import ClipartsTable from './cliparts/clipartsTable';
import AddCliparts from './cliparts/addCliparts';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ArtWorkActions } from '../_redux/artwork.action';
import { DeleteClipartModal } from './cliparts/deleteClipartModal';

const Cliparts = () => {

    const [selectedClipart, setSelectedClipart] = useState(null);

    const dispatch = useDispatch();

    const onOpenClipartModal = () => {
        dispatch(ArtWorkActions.openAddCliparts());
    }

    const onCloseClipartModal = () => {
        dispatch(ArtWorkActions.closeAddCliparts());
        setSelectedClipart(null);
    }

    const onOpenDeleteClipartModal = (row) => {
        setSelectedClipart(row)
        dispatch(ArtWorkActions.openDeleteClipartModal());
    }

    const onCloseDeleteClipartModal = () => {
        dispatch(ArtWorkActions.closeDeleteClipartModal());
        setSelectedClipart(null);
    }

    const onDeleteClipArt = (_id) => {
        if (selectedClipart) {
            dispatch(ArtWorkActions.deleteClipart(selectedClipart._id))
            onCloseDeleteClipartModal()
        }
    }

    const onAddClipart = (values) => {
        let data = new FormData();
        data.set('categoryName', values.categoryName);
        values.images.forEach(img => {
            data.append('images', img);
        })
        dispatch(ArtWorkActions.addCliparts(data));
    }

    const onUpdateCliparts = (values, imageDetails) => {
        let data = new FormData();
        data.set('categoryName', values.categoryName);
        data.set('_id', imageDetails._id);
        if (typeof values.images === "string") {
            data.set('images', values.images);
        } else {
            values.images.forEach(img => {
                data.append('images', img);
            })
        }
        dispatch(ArtWorkActions.editClipart(data));
    }

    const statusClipart = (data, isActive) => {
        dispatch(ArtWorkActions.updateClipartStatus({ _id: data._id, isActive }))
    }

    const { modalAddCliparts, modalDeleteCliparts, isLoading, categoryList, selectedCategory } = useSelector(state => state.artwork, shallowEqual)

    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: 'space-between',
                alignItems:'center',
                marginTop: '10px'
            }}>
                <div>
                    <label className="mb-3">
                        Filter by Category
                    </label>
                    <select
                        className="form-control"
                        placeholder="Filter by Status"
                        style={{ minWidth: "100%" }}
                        value={selectedCategory}
                        onChange={(e) =>
                            dispatch(ArtWorkActions.setSelectedCategory(e.target.value))
                        }
                    >
                        <option value="">All</option>
                        {
                            categoryList.map((item, index) => (
                                <option key={index} value={item._id}>{item._id}</option>
                            ))
                        }
                    </select>
                </div>
                <button
                    type="button"
                    className="btn btn-primary mt-5"
                    onClick={onOpenClipartModal}
                >
                    Add
                </button>
            </div>
            <Modal size="lg" show={modalAddCliparts}>
                <Modal.Header >
                    <Modal.Title>
                        <h5 className='float-right'>Add Cliparts</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddCliparts
                        onCloseClipartModal={onCloseClipartModal}
                        onAddClipart={onAddClipart}
                        selectedClipart={selectedClipart}
                        onUpdateCliparts={onUpdateCliparts}
                    />
                </Modal.Body>
            </Modal>
            <ClipartsTable
                onOpenDeleteClipartModal={onOpenDeleteClipartModal}
                onOpenClipartModal={onOpenClipartModal}
                setSelectedClipart={setSelectedClipart}
                statusClipart={statusClipart}
            />
            <DeleteClipartModal
                onDeleteClipArt={onDeleteClipArt}
                onCloseDeleteClipartModal={onCloseDeleteClipartModal}
                modalDeleteCliparts={modalDeleteCliparts}
                isLoading={isLoading}
            />
        </div>
    )
}

export default Cliparts