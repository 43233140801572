/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { SnackbarProvider } from 'notistack';
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {

    const dispatch = useDispatch();

    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user.email && auth.tokens && !auth.errors,
        }),
        shallowEqual
    );

    const onSnackbarClose = (e, r, k) => {
        dispatch({ type: "REMOVE_SNACKBAR" })
    }

    return (
        <SnackbarProvider maxSnack={10}
            onClose={onSnackbarClose}
        >
            <Switch>
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage />
                    </Route>
                ) : (
                        /*Otherwise redirect to root page (`/`)*/
                        <Redirect from="/auth" to="/" />
                    )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={Logout} />


                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/auth/login" />
                ) : (
                        <Layout>
                            <BasePage />
                        </Layout>
                    )}
            </Switch>
        </SnackbarProvider>
    );
}
