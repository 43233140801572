import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actions, actionTypes } from './auth.actions';

const initialAuthState = {
    user: {
        _id: "",
        firstName: "",
        lastName: "",
        fullname: "",
        mobileNo: "",
        email: "",
        createdAt: "",
        username: "",
    },
    tokens: "",
    isLoading: false,
    errors: "",
    message: "",
    redirectLogin: false,
};

export const reducer = persistReducer(
    { storage, key: "v711-demo1-auth", whitelist: ["user", "tokens"] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.FORGOT_PASSWORD:
            case actionTypes.RESET_PASSWORD:
            case actionTypes.Login_START: {
                return {
                    ...state,
                    isLoading: true,
                    errors: "",
                    message: "",
                };
            }
            case actionTypes.LOGIN_SUCCESS: {
                return {
                    ...state,
                    user: action.payload.user,
                    tokens: action.payload.tokens,
                    isLoading: false,
                    errors: "",
                };
            }
            case actionTypes.LOGIN_ERROR: {
                return {
                    ...state,
                    isLoading: false,
                    errors: action.payload.errors,
                    message: "",
                };
            }
            case actionTypes.Logout: {
                return { ...initialAuthState }
            }
            case actionTypes.RESET_PASSWORD_SUCCESS:
            case actionTypes.FORGOT_PASSWORD_SUCCESS: {
                return {
                    ...state,
                    message: action.payload,
                    errors: "",
                    isLoading: false,
                    redirectLogin: true,
                }
            }
            case actionTypes.RESET_PASSWORD_ERROR:
            case actionTypes.FORGOT_PASSWORD_ERROR: {
                return {
                    ...state,
                    message: "",
                    errors: action.payload.errors,
                    isLoading: false,
                    redirectLogin: false
                }
            }
            case actionTypes.REDIRECT_FORGOT_PSWD: {
                return {
                    ...state,
                    message: "",
                    errors: "",
                    redirectLogin: false,
                    isLoading: false,
                }
            }
            default:
                return state;
        }
    }
);