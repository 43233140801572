export const actionTypes = {
    GET_USERS_LIST: "GET_USERS_LIST",
    GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
    GET_USERS_LIST_ERROR: "GET_USERS_LIST_ERROR",
    SET_USER_PAGE: "SET_USER_PAGE",
    SET_USER_PAGE_SIZE: "SET_USER_PAGE_SIZE",

    ACTIVATION_USER: 'ACTIVATION_USER',
    ACTIVATION_USER_SUCCESS: 'ACTIVATION_USER_SUCCESS',
    ACTIVATION_USER_ERROR: 'ACTIVATION_USER_ERROR',
}

export const actions = {
    getuserList: (pagination) => ({ type: actionTypes.GET_USERS_LIST, payload: pagination }),
    getuserListSuccess: (data) => ({ type: actionTypes.GET_USERS_LIST_SUCCESS, payload: data }),
    getuserListError: (errors) => ({ type: actionTypes.GET_USERS_LIST_ERROR, payload: { errors } }),
    setPage: (num) => ({type: actionTypes.SET_USER_PAGE, payload: num}),
    setPageSize: (num) => ({type: actionTypes.SET_USER_PAGE_SIZE, payload: num}),

    activateUserStatus: (data) => ({type: actionTypes.ACTIVATION_USER, payload: data}),
    activateUserStatusSuccess: (data) => ({type: actionTypes.ACTIVATION_USER_SUCCESS}),
    activateUserStatusError: (data) => ({type: actionTypes.ACTIVATION_USER_ERROR}),
}
