import axios from 'axios';
// import {serverConfig} from '../../../utils/constants';

const getErrorMsg = (data) => {
    if(data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if(data.error) {
        return data.error.message || data.error.errors[0].responseMessage;
    }
    return "Please try again after sometime.";
}

export const allDomainListApi = async ({batch, limit, tokens}) => {
    try {
        let { data } = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/domains/getAllDomainsList/${batch}/${limit}`,
            headers: {
                "Authorization": `Bearer ${tokens}`,
            }
        });
    
        if(data.response && data.response.responseCode === 200) {
            return data.response.data;
        }
    
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)        
    }
}