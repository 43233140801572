import { put, takeLatest, call, all, fork } from "redux-saga/effects";
import { actionTypes, actions } from './auth.actions';
import { loginApi, forgotPasswordApi, resetPasswordApi } from './auth.api';

function* watchLoginSaga({payload}) {
    try {
        const data = yield call(loginApi, payload);
        if(data.tokens) {
            yield put(actions.loginSuccess(data));
            return true; 
        }
        throw new Error("Login Error.");
    } catch (error) {
        yield put(actions.loginError(error.message));
    }
}

function* watchForgotPassword({payload}) {
    try {
        const data = yield call(forgotPasswordApi, payload);
        if(data.responseCode === 200) {
            yield put(actions.forgotPasswordSuccess(data.responseMessage));
            return true;
        }
        throw new Error("Error sending mail. Try again after sometime.");
    } catch (error) {
        console.log(error.message)
        yield put(actions.forgotPasswordError(error.message));
    }
}

function* watchResetPassword({payload}) {
    try {
        const data = yield call(resetPasswordApi, payload);
        if(data.responseCode === 200) {
            yield put(actions.resetPasswordSuccess(data.responseMessage));
            return true;
        }
        throw new Error("Error sending mail. Try again after sometime.");
    } catch (error) {
        console.log(error.message)
        yield put(actions.resetPasswordError(error.message));
    }
}

function* loginSaga() {
    yield takeLatest(actionTypes.Login_START, watchLoginSaga);
}

function* forgotPasswordSaga() {
    yield takeLatest(actionTypes.FORGOT_PASSWORD, watchForgotPassword)
}

function* resetPasswordSaga() {
    yield takeLatest(actionTypes.RESET_PASSWORD, watchResetPassword)
}

export function* authSaga() {
    yield all([
        fork(loginSaga),
        fork(forgotPasswordSaga),
        fork(resetPasswordSaga),
    ]);
}