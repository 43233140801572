import { put, takeLatest, call, all, fork, select } from 'redux-saga/effects';
import { ArtWorkHistoryMap, ArtWorkHistoryActions } from './artworkHistory.action';
import { getAllArtWorkHistoryApi } from './artworkHistory.api';

function* watchArtWorkHistorySaga() {
    try {
        const {
            pageNumber,
            pageSize
        } = yield select(state => state.artworkHistory);
        const data = yield call(getAllArtWorkHistoryApi, {pageNumber, pageSize});
        yield put(ArtWorkHistoryActions.getAllArtworkHistoryListSuccess(data));
    } catch (error) {
        yield put(ArtWorkHistoryActions.getAllArtworkHistoryListError(error.message));
    }
}

function* AllArtWorkHistoryListSaga() {
    yield takeLatest(ArtWorkHistoryMap.GET_ALL_ARTWORK_HISTORY_LIST_START, watchArtWorkHistorySaga);
}

export function* artWorkHistorySaga() {
    yield all([
        fork(AllArtWorkHistoryListSaga),
    ])
}