import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function ActionFormatter(cellContent, row, rowIndex, {onArtworkDownload}) {
    return (
        <>
            <a
                title="Download Artwork"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => onArtworkDownload(row)}
            >
                <span title="Download Artwork" className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG title="Download Artwork"
                        src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}
                    />
                </span>
            </a>

        </>
    );
}
