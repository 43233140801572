export const actionTypes = {
    Login_START: "Login_START",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",
    Logout: "[Logout] Action",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
    REDIRECT_FORGOT_PSWD: "REDIRECT_FORGOT_PSWD",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
};

export const actions = {
    login: (data) => ({ type: actionTypes.Login_START, payload: data }),
    loginSuccess: (data) => ({ type: actionTypes.LOGIN_SUCCESS, payload: data }),
    loginError: (errors) => ({ type: actionTypes.LOGIN_ERROR, payload: { errors } }),
    logout: () => ({ type: actionTypes.Logout }),
    forgotPassword: (email) => ({ type: actionTypes.FORGOT_PASSWORD, payload: email }),
    forgotPasswordSuccess: (data) => ({ type: actionTypes.FORGOT_PASSWORD_SUCCESS, payload: data }),
    forgotPasswordError: (errors) => ({ type: actionTypes.FORGOT_PASSWORD_ERROR, payload: { errors } }),
    resetPassword: (data) => ({ type: actionTypes.RESET_PASSWORD, payload: data }),
    resetPasswordSuccess: (data) => ({ type: actionTypes.RESET_PASSWORD_SUCCESS, payload: data }),
    resetPasswordError: (errors) => ({ type: actionTypes.RESET_PASSWORD_ERROR, payload: { errors } }),
    redirectForgotPswd: () => ({ type: actionTypes.REDIRECT_FORGOT_PSWD }),
};
