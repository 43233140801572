import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Modal, Button } from 'react-bootstrap';
import { Input } from '../../../../../_metronic/_partials/controls';

const AddImageSchema = () => (
    Yup.object().shape({
        categoryName: Yup.string().trim()
            .required("Category is required")
    }));

const AddCliparts = ({ onCloseClipartModal, onAddClipart, selectedClipart, onUpdateCliparts }) => {
    return (
        <>
            <Formik
                initialValues={{
                    categoryName: selectedClipart ? selectedClipart.categoryName : "",
                    images: selectedClipart ? selectedClipart.imageUrl : ""
                }}

                validationSchema={AddImageSchema}

                onSubmit={(values) => {
                    if(selectedClipart) {
                        onUpdateCliparts(values, selectedClipart)
                    } else {
                        onAddClipart(values);
                    }
                    onCloseClipartModal();
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <>
                        <Modal.Body className="overlay overlay-block">
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <Field
                                            name="categoryName"
                                            component={Input}
                                            placeholder="Add Category"
                                            label="Add Category"
                                        />
                                    </div>
                                </div>
                                {
                                    values.images && typeof values.images === "string" ? (
                                        <div className="form-group row">
                                            <div className="col-lg-6">
                                                <img src={values.images} height="200" />
                                            </div>
                                        </div>
                                    ) : null
                                }
                                <div className="upload_document_file">
                                    {/* <div htmlFor="inputGroupFile03">
                                        <UploadPlusIcon />
                                    </div> */}
                                    {/* <label htmlFor="inputGroupFile03" className="upload_document" >UPLOAD IMAGE</label> */}
                                    <input
                                        type="file"
                                        multiple
                                        // className="custom-file-input"
                                        // id="inputGroupFile03"
                                        accept="image/*"
                                        onChange={event => setFieldValue('images', event.target.files)}
                                    />
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onCloseClipartModal}
                                className="float-right"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                onClick={() => handleSubmit()}
                                className="float-right"
                            >
                                { selectedClipart ? "EDIT" :  "ADD"}
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AddCliparts;
