export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		config => {
			const {
				auth: { tokens }
			} = store.getState();

			if (tokens) {
				config.headers.Authorization = `Bearer ${tokens}`;
			}

			return config;
		},
		err => Promise.reject(err)
	);
}
