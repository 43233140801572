import axios from 'axios';

const getErrorMsg = (data) => {
    if (data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if (data.error) {
        return data.error.message || data.error.errors[0].responseMessage;
    }
    return "Please try again after sometime.";
}

export const getAllClipartsApi = async ({ batch, limit, selectedCategory }) => {
    try {
        console.log(selectedCategory);
        let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/cliparts/getAllCliparts/${batch}/${limit}`;
        if(selectedCategory) {
            url += `/${selectedCategory}`
        }
        let { data } = await axios({
            method: "GET",
            url,
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)
    }
}

export const addClipartsApi = async (values) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/cliparts/addCliparts`,
            data: values,
            headers: {
                'Content-Type': 'form/multipart',
            }
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)
    }
}

export const deleteClipartApi = async (_id) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/cliparts/deleteCliparts/${_id}`,
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const editClipartApi = async (values) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/cliparts/updateCliparts`,
            data: values,
            headers: {
                'Content-Type': 'form/multipart',
            }
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const updateClipartStatusApi = async ({ _id, isActive }) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/cliparts/updateStatus/${_id}`,
            headers: {
                "Content-Type": "application/json"
            },
            data: { isActive }
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const getAllShapesApi = async ({ batch, limit }) => {
    try {
        let { data } = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/shapes/getAllShapes/${batch}/${limit}`,
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)
    }
}

export const addShapesApi = async (values) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/shapes/addShapes`,
            data: values,
            headers: {
                'Content-Type': 'form/multipart',
            }
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.data;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        throw new Error(error.message)
    }
}

export const deleteShapesApi = async (_id) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/shapes/deleteShapes/${_id}`,
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const editShapesApi = async (values) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/shapes/updateShapes`,
            data: values,
            headers: {
                'Content-Type': 'form/multipart',
            }
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const updateShapesStatusApi = async ({ _id, isActive }) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/shapes/updateStatus/${_id}`,
            headers: {
                "Content-Type": "application/json"
            },
            data: { isActive }
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}


