import { all, fork } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux";
import * as domains from "../app/modules/domains/_redux";
import * as products from '../app/modules/products/_redux';
import * as artwork from '../app/modules/artwork/_redux';
import * as artworkHistory from '../app/modules/ArtWorkHistory/_redux';
import * as users from '../app/modules/user-management/_redux';
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";

import {SanckbarReducer} from './snackbarReducer';
//import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
	auth: auth.reducer,
	domains: domains.reducer,
	products: products.reducer,
	artwork: artwork.reducer,
	artworkHistory: artworkHistory.reducer,
	customers: customersSlice.reducer,
	users: users.reducer,
	snackbar: SanckbarReducer,
	//products: productsSlice.reducer,
	remarks: remarksSlice.reducer,
	specifications: specificationsSlice.reducer
});

export function* rootSaga() {
	yield all([
		fork(auth.authSaga),
		fork(domains.domainsSaga),
		fork(products.productsSaga),
		fork(artwork.clipartsSaga),
		fork(artworkHistory.artWorkHistorySaga),
		fork(users.usersSaga)
	]);
}
