import React from 'react';
import { ArtworkManagement } from '../modules/artwork';

const ArtworkManagementPage = () => {
    return (
        <div>
            <ArtworkManagement />
        </div>
    )
}

export { ArtworkManagementPage };
