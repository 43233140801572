import {actionTypes} from './users.action';

const initState = {
    usersList: [],
    isLoading: true,
    errors: "",
    totalRecords: 0,
    pageNumber: 1,
    pageSize: 10,
    refreshUsers: true,
}

export const reducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.GET_USERS_LIST: {
            return {
                ...state,
                isLoading: true,
                errors: "",
            }
        }
        case actionTypes.GET_USERS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                usersList: action.payload.list,
                totalRecords: action.payload.total,
                refreshUsers: false,
            }
        }
        case actionTypes.GET_USERS_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case actionTypes.SET_USER_PAGE: {
            return {
                ...state,
                pageNumber: action.payload,
                refreshUsers: true,
            }
        }
        case actionTypes.SET_USER_PAGE_SIZE: {
            return {
                ...state,
                pageSize: action.payload,
                refreshUsers: true,
            }
        }

        case actionTypes.ACTIVATION_USER_SUCCESS: {
            return {
                ...state,
                refreshUsers: true,
            }
        }
      
        default: {
            return {
                ...state,
            }
        }
    }
}