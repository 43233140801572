import React from "react";
import { Modal, Button } from 'react-bootstrap';

export const DeleteShapeModal = (
    { modalDeleteShapes, onCloseDeleteShapesModal, isLoading, onDeleteShapes }
) => {
    return (
        <Modal size="lg" show={modalDeleteShapes} onHide={onCloseDeleteShapesModal}
        >
            <Modal.Header >
                <Modal.Title>
                    <h5 className='float-left'>Delete Shape</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block">
                <div>
                    Are you sure to delete this shape permanently?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    variant="secondary"
                    onClick={onCloseDeleteShapesModal}
                    className="float-right"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    onClick={onDeleteShapes}
                    className="float-right"
                    disabled={isLoading}
                >
                    Delete
                    {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}