export const ArtWorkMap = {
    OPEN_CLIPARTS_MODAL: 'OPEN_CLIPARTS_MODAL',
    CLOSE_CLIPARTS_MODAL: 'CLOSE_CLIPARTS_MODAL',
    OPEN_DELETE_CLIPART_MODAL: 'OPEN_DELETE_CLIPART_MODAL',
    CLOSE_DELETE_CLIPART_MODAL: 'CLOSE_DELETE_CLIPART_MODAL',
    SET_CLIPART_PAGE: 'SET_CLIPART_PAGE',
    SET_CLIPART_PAGE_SIZE: 'SET_CLIPART_PAGE_SIZE',
    SET_SHAPES_PAGE: 'SET_SHAPES_PAGE',
    SET_SHAPES_PAGE_SIZE: 'SET_SHAPES_PAGE_SIZE',
    ADD_CLIPARTS_START: 'ADD_CLIPARTS_START',
    ADD_CLIPARTS_SUCCESS: 'ADD_CLIPARTS_SUCCESS',
    ADD_CLIPARTS_ERROR: 'ADD_CLIPARTS_ERROR',
    GET_ALL_CLIPARTS_LIST_START: 'GET_ALL_CLIPARTS_LIST_START',
    GET_ALL_CLIPARTS_LIST_SUCCESS: 'GET_ALL_CLIPARTS_LIST_SUCCESS',
    GET_ALL_CLIPARTS_LIST_ERROR: 'GET_ALL_CLIPARTS_LIST_ERROR',
    DELETE_CLIPART_START: 'DELETE_CLIPART_START',
    DELETE_CLIPART_SUCCESS: 'DELETE_CLIPART_SUCCESS',
    DELETE_CLIPART_ERROR: 'DELETE_CLIPART_ERROR',
    EDIT_CLIPART_START: 'EDIT_CLIPART_START',
    EDIT_CLIPART_SUCCESS: 'EDIT_CLIPART_SUCCESS',
    EDIT_CLIPART_ERROR: 'EDIT_CLIPART_ERROR',
    UPDATE_CLIPART_STATUS_START: 'UPDATE_CLIPART_STATUS_START',
    UPDATE_CLIPART_STATUS_SUCCESS: 'UPDATE_CLIPART_STATUS_SUCCESS',
    UPDATE_CLIPART_STATUS_ERROR: 'UPDATE_CLIPART_STATUS_ERROR',
    OPEN_ADD_SHAPES_MODAL: 'OPEN_ADD_SHAPES_MODAL',
    CLOSE_ADD_SHAPES_MODAL: 'CLOSE_ADD_SHAPES_MODAL',
    OPEN_DELETE_SHAPES_MODAL: 'OPEN_DELETE_SHAPES_MODAL',
    CLOSE_DELETE_SHAPES_MODAL: 'CLOSE_DELETE_SHAPES_MODAL',
    ADD_SHAPES_START: 'ADD_SHAPES_START',
    ADD_SHAPES_SUCCESS: 'ADD_SHAPES_SUCCESS',
    ADD_SHAPES_ERROR: 'ADD_SHAPES_ERROR',
    GET_ALL_SHAPES_LIST_START: 'GET_ALL_SHAPES_LIST_START',
    GET_ALL_SHAPES_LIST_SUCCESS: 'GET_ALL_SHAPES_LIST_SUCCESS',
    GET_ALL_SHAPES_LIST_ERROR: 'GET_ALL_SHAPES_LIST_ERROR',
    DELETE_SHAPES_START: 'DELETE_SHAPES_START',
    DELETE_SHAPES_SUCCESS: 'DELETE_SHAPES_SUCCESS',
    DELETE_SHAPES_ERROR: 'DELETE_SHAPES_ERROR',
    EDIT_SHAPES_START: 'EDIT_SHAPES_START',
    EDIT_SHAPES_SUCCESS: 'EDIT_SHAPES_SUCCESS',
    EDIT_SHAPES_ERROR: 'EDIT_SHAPES_ERROR',
    UPDATE_SHAPES_STATUS_START: 'UPDATE_SHAPES_STATUS_START',
    UPDATE_SHAPES_STATUS_SUCCESS: 'UPDATE_SHAPES_STATUS_SUCCESS',
    UPDATE_SHAPES_STATUS_ERROR: 'UPDATE_SHAPES_STATUS_ERROR',
    SELECTED_CATEGORY: 'SELECTED_CATEGORY',
}

export const ArtWorkActions = {
    openAddCliparts: () => ({ type: ArtWorkMap.OPEN_CLIPARTS_MODAL }),
    closeAddCliparts: () => ({ type: ArtWorkMap.CLOSE_CLIPARTS_MODAL }),

    openDeleteClipartModal: () => ({ type: ArtWorkMap.OPEN_DELETE_CLIPART_MODAL }),
    closeDeleteClipartModal: () => ({ type: ArtWorkMap.CLOSE_DELETE_CLIPART_MODAL }),

    openAddShapes: () => ({ type: ArtWorkMap.OPEN_ADD_SHAPES_MODAL }),
    closeAddShapes: () => ({ type: ArtWorkMap.CLOSE_ADD_SHAPES_MODAL }),

    setClipartPage: (num) => ({ type: ArtWorkMap.SET_CLIPART_PAGE, payload: num }),
    setClipartPageSize: (num) => ({ type: ArtWorkMap.SET_CLIPART_PAGE_SIZE, payload: num }),

    setShapesPage: (num) => ({ type: ArtWorkMap.SET_SHAPES_PAGE, payload: num }),
    setShapesPageSize: (num) => ({ type: ArtWorkMap.SET_SHAPES_PAGE_SIZE, payload: num }),

    addCliparts: (data) => ({ type: ArtWorkMap.ADD_CLIPARTS_START, payload: data }),
    addClipartsSuccess: () => ({ type: ArtWorkMap.ADD_CLIPARTS_SUCCESS }),
    addClipartsError: () => ({ type: ArtWorkMap.ADD_CLIPARTS_ERROR }),

    getAllClipartsList: (data) => ({ type: ArtWorkMap.GET_ALL_CLIPARTS_LIST_START, payload: data }),
    getAllClipartsListSuccess: (data) => ({ type: ArtWorkMap.GET_ALL_CLIPARTS_LIST_SUCCESS, payload: data }),
    getAllClipartsListError: () => ({ type: ArtWorkMap.GET_ALL_CLIPARTS_LIST_ERROR }),

    setSelectedCategory: (data) => ({type: ArtWorkMap.SELECTED_CATEGORY, payload: data}),

    deleteClipart: (data) => ({ type: ArtWorkMap.DELETE_CLIPART_START, payload: data }),
    deleteClipartSuccess: () => ({ type: ArtWorkMap.DELETE_CLIPART_SUCCESS }),
    deleteClipartError: (data) => ({ type: ArtWorkMap.DELETE_CLIPART_ERROR, payload: data }),

    editClipart: (data) => ({ type: ArtWorkMap.EDIT_CLIPART_START, payload: data }),
    editClipartSuccess: () => ({ type: ArtWorkMap.EDIT_CLIPART_SUCCESS }),
    editClipartError: () => ({ type: ArtWorkMap.EDIT_CLIPART_ERROR }),

    updateClipartStatus: (data) => ({ type: ArtWorkMap.UPDATE_CLIPART_STATUS_START, payload: data }),
    updateClipartStatusSuccess: () => ({ type: ArtWorkMap.UPDATE_CLIPART_STATUS_SUCCESS }),
    updateClipartStatusError: () => ({ type: ArtWorkMap.UPDATE_CLIPART_STATUS_ERROR }),



    openAddShapes: () => ({ type: ArtWorkMap.OPEN_ADD_SHAPES_MODAL }),
    closeAddShapes: () => ({ type: ArtWorkMap.CLOSE_ADD_SHAPES_MODAL }),

    openDeleteShapeModal: () => ({ type: ArtWorkMap.OPEN_DELETE_SHAPES_MODAL }),
    closeDeleteShapeModal: () => ({ type: ArtWorkMap.CLOSE_DELETE_SHAPES_MODAL }),

    addShapes: (data) => ({ type: ArtWorkMap.ADD_SHAPES_START, payload: data }),
    addShapesSuccess: () => ({ type: ArtWorkMap.ADD_SHAPES_SUCCESS }),
    addShapesError: () => ({ type: ArtWorkMap.ADD_SHAPES_ERROR }),

    getAllShapesList: (data) => ({ type: ArtWorkMap.GET_ALL_SHAPES_LIST_START, payload: data }),
    getAllShapesListSuccess: (data) => ({ type: ArtWorkMap.GET_ALL_SHAPES_LIST_SUCCESS, payload: data }),
    getAllShapesListError: () => ({ type: ArtWorkMap.GET_ALL_SHAPES_LIST_ERROR }),

    deleteShapes: (data) => ({ type: ArtWorkMap.DELETE_SHAPES_START, payload: data }),
    deleteShapesSuccess: () => ({ type: ArtWorkMap.DELETE_SHAPES_SUCCESS }),
    deleteShapesError: (data) => ({ type: ArtWorkMap.DELETE_SHAPES_ERROR, payload: data }),

    editShapes: (data) => ({ type: ArtWorkMap.EDIT_SHAPES_START, payload: data }),
    editShapesSuccess: () => ({ type: ArtWorkMap.EDIT_SHAPES_SUCCESS }),
    editShapesError: () => ({ type: ArtWorkMap.EDIT_SHAPES_ERROR }),

    updateShapesStatus: (data) => ({ type: ArtWorkMap.UPDATE_SHAPES_STATUS_START, payload: data }),
    updateShapesStatusSuccess: () => ({ type: ArtWorkMap.UPDATE_SHAPES_STATUS_SUCCESS }),
    updateShapesStatusError: () => ({ type: ArtWorkMap.UPDATE_SHAPES_STATUS_ERROR })
}