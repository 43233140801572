import { put, takeLatest, call, all, fork, select } from 'redux-saga/effects';
import { ArtWorkMap, ArtWorkActions } from './artwork.action';
import {
    getAllClipartsApi, addClipartsApi, deleteClipartApi, editClipartApi, updateClipartStatusApi,
    getAllShapesApi, addShapesApi, deleteShapesApi, editShapesApi, updateShapesStatusApi
} from './artwork.api';

function* watchClipartsSaga({ payload }) {
    try {
        const data = yield call(getAllClipartsApi, { ...payload });
        yield put(ArtWorkActions.getAllClipartsListSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.getAllClipartsListError(error.message));
    }
}

function* watchAddClipartsSaga({ payload }) {
    try {
        const data = yield call(addClipartsApi, payload);
        yield put(ArtWorkActions.addClipartsSuccess());
    } catch (error) {
        yield put(ArtWorkActions.addClipartsError(error.message));
    }
}

function* watchDeleteClipartSaga({ payload }) {
    try {
        const data = yield call(deleteClipartApi, payload);
        yield put(ArtWorkActions.deleteClipartSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.deleteClipartError(error.message));
    }
}

function* watchEditClipartSaga({ payload }) {
    try {
        const data = yield call(editClipartApi, payload);
        yield put(ArtWorkActions.editClipartSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.editClipartError(error.message));
    }
}

function* watchUpdateClipartStatusSaga({ payload }) {
    try {
        const data = yield call(updateClipartStatusApi, payload);
        yield put(ArtWorkActions.updateClipartStatusSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.updateClipartStatusError(error.message));
    }
}

function* watchShapesSaga({ payload }) {
    try {
        const data = yield call(getAllShapesApi, { ...payload });
        yield put(ArtWorkActions.getAllShapesListSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.getAllShapesListError(error.message));
    }
}

function* watchAddShapesSaga({ payload }) {
    try {
        const data = yield call(addShapesApi, payload);
        yield put(ArtWorkActions.addShapesSuccess());
    } catch (error) {
        yield put(ArtWorkActions.addShapesError(error.message));
    }
}

function* watchDeleteShapesaga({ payload }) {
    try {
        const data = yield call(deleteShapesApi, payload);
        yield put(ArtWorkActions.deleteShapesSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.deleteShapesError(error.message));
    }
}

function* watchEditShapesaga({ payload }) {
    try {
        const data = yield call(editShapesApi, payload);
        yield put(ArtWorkActions.editShapesSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.editShapesError(error.message));
    }
}

function* watchUpdateShapestatusSaga({ payload }) {
    try {
        const data = yield call(updateShapesStatusApi, payload);
        yield put(ArtWorkActions.updateShapesStatusSuccess(data));
    } catch (error) {
        yield put(ArtWorkActions.updateShapesStatusError(error.message));
    }
}

function* AllClipartsListSaga() {
    yield takeLatest(ArtWorkMap.GET_ALL_CLIPARTS_LIST_START, watchClipartsSaga);
}

function* AddClipartsSaga() {
    yield takeLatest(ArtWorkMap.ADD_CLIPARTS_START, watchAddClipartsSaga);
}

function* deleteClipartSaga() {
    yield takeLatest(ArtWorkMap.DELETE_CLIPART_START, watchDeleteClipartSaga);
}

function* editClipartSaga() {
    yield takeLatest(ArtWorkMap.EDIT_CLIPART_START, watchEditClipartSaga);
}

function* updateClipartStatusSaga() {
    yield takeLatest(ArtWorkMap.UPDATE_CLIPART_STATUS_START, watchUpdateClipartStatusSaga);
}

function* AllShapesListSaga() {
    yield takeLatest(ArtWorkMap.GET_ALL_SHAPES_LIST_START, watchShapesSaga);
}

function* AddShapesSaga() {
    yield takeLatest(ArtWorkMap.ADD_SHAPES_START, watchAddShapesSaga);
}

function* deleteShapesSaga() {
    yield takeLatest(ArtWorkMap.DELETE_SHAPES_START, watchDeleteShapesaga);
}

function* editShapesSaga() {
    yield takeLatest(ArtWorkMap.EDIT_SHAPES_START, watchEditShapesaga);
}

function* updateShapestatusSaga() {
    yield takeLatest(ArtWorkMap.UPDATE_SHAPES_STATUS_START, watchUpdateShapestatusSaga);
}

export function* clipartsSaga() {
    yield all([
        fork(AllClipartsListSaga),
        fork(AddClipartsSaga),
        fork(deleteClipartSaga),
        fork(editClipartSaga),
        fork(updateClipartStatusSaga),
        fork(AllShapesListSaga),
        fork(AddShapesSaga),
        fork(deleteShapesSaga),
        fork(editShapesSaga),
        fork(updateShapestatusSaga),
    ])
}