import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {DomainsTable} from './domainsTable';

export function DomainsCard() {
  return (
    <Card>
      <CardHeader title="Domains list">
      </CardHeader>
      <CardBody>
        <DomainsTable />
      </CardBody>
    </Card>
  );
}
