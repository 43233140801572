export const ArtWorkHistoryMap = {
    GET_ALL_ARTWORK_HISTORY_LIST_START: 'GET_ALL_ARTWORK_HISTORY_LIST_START',
    GET_ALL_ARTWORK_HISTORY_LIST_SUCCESS: 'GET_ALL_ARTWORK_HISTORY_LIST_SUCCESS',
    GET_ALL_ARTWORK_HISTORY_LIST_ERROR: 'GET_ALL_ARTWORK_HISTORY_LIST_ERROR',
    SET_ARTWORKHISTORY_PAGE: "SET_ARTWORKHISTORY_PAGE",
    SET_ARTWORKHISTORY_PAGE_SIZE: "SET_ARTWORKHISTORY_PAGE_SIZE",
}

export const ArtWorkHistoryActions = {
    getAllArtworkHistoryList: (data) => ({ type: ArtWorkHistoryMap.GET_ALL_ARTWORK_HISTORY_LIST_START, payload: data }),
    getAllArtworkHistoryListSuccess: (data) => ({ type: ArtWorkHistoryMap.GET_ALL_ARTWORK_HISTORY_LIST_SUCCESS, payload: data }),
    getAllArtworkHistoryListError: () => ({ type: ArtWorkHistoryMap.GET_ALL_ARTWORK_HISTORY_LIST_ERROR }),

    setPage: (num) => ({ type: ArtWorkHistoryMap.SET_ARTWORKHISTORY_PAGE, payload: num }),
    setPageSize: (num) => ({ type: ArtWorkHistoryMap.SET_ARTWORKHISTORY_PAGE_SIZE, payload: num }),
}