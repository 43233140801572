import React from 'react';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Switch from '@material-ui/core/Switch';

export const ShapesFormatter = (
    cellContent,
    row,
    rowIndex,
    { onOpenDeleteShapesModal, onOpenShapesModal, setSelectedShape, statusShape }
) => {

    const onEditShape = () => {
        setSelectedShape(row);
        onOpenShapesModal();
    }

    return (
        <>
            {/* <a
                title="Edit shapes"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={onEditShape}
            >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG title="Edit shapes"
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                    />
                </span>
            </a> */}
            <a
                title="Delete shapes"
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => { onOpenDeleteShapesModal(row) }}
            >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG title="Delete shapes" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                </span>
            </a>
            <Switch
                checked={row.isActive}
                size="small"
                className="mt-1"
                color="secondary"
                name="boundaryBoxDefault"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => { statusShape(row, e.target.checked) }}
            />
        </>
    )
}